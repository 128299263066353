/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import { Modal } from 'react-bootstrap'
import NotificationType from '../../../../models/NotificationType'
import NotificationTypeService from '../../../../services/promo.service'
import NotificationEvent from '../../../../models/NotificationEvent'
import NotificationService from '../../../../services/notification.service'
// form 
const CreateTypeForm = (
  {
    init,
    id,
    handleSubmit,
  }: {
    init: NotificationType | null,
    id: number,
    handleSubmit: (notificationType: NotificationType) => void
  }
) => {
const [notificationType, setNotificationType] = React.useState<NotificationType>({
    ...init,
    id: 0,
    title: '',
    notficationPriorty: '',
    tygoEventId: 0,
    sendByEmail: false,
    sendBySMS: false,
    sendByPush: false,
    sendByWhatsapp: false
})

const formattedDate = (date: string) => {
    const d = new Date(date)
    const year = d.getFullYear()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
}
  
  React.useEffect(() => {
    if(!init && id)
    fetchNotificationType()
    else if(init)
    {
      setNotificationType({
        ...init,
        // startdate: formattedDate(init.startdate),
        // enddate: formattedDate(init.enddate)
      })
    }
    fetchEvents()

  }, [id])
  
  const fetchNotificationType = async () => {
    const response = await NotificationTypeService.getById(id);
    setNotificationType(response.data)
  }
  
  const [events, setEvents] = React.useState<NotificationEvent[]>([])
   
    const fetchEvents = async () => {
        const response = await NotificationService.getEvents();
        setEvents(response.data)
    }

  // submit form


  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      handleSubmit(notificationType)
    }}>

      <div className="form-group mt-3">
        <label htmlFor="title">Title</label>
        <input type="text" className="form-control mt-2" id="title" name="title" value={notificationType?.title} onChange={(e) => setNotificationType({...notificationType, title: e.target.value})} />
      </div>
    <div className="form-group mt-3">
        <label htmlFor="notficationPriorty">Notfication Priorty</label>
        {/* <input type="text" className="form-control mt-2" id="notficationPriorty" name="notficationPriorty" value={notificationType?.notficationPriorty} onChange={(e) => setNotificationType({...notificationType, notficationPriorty: e.target.value})} /> */}
        <select className="form-select mt-2" id="notficationPriorty" name="notficationPriorty" value={notificationType?.notficationPriorty} onChange={(e) => setNotificationType({...notificationType, notficationPriorty: e.target.value})}>
            <option value="1">High</option>
            <option value="2">Medium</option>
            <option value="3">Low</option>
          </select>
    </div>
    <div className="row mt-3">
    <div className="col mt-3">
        <label htmlFor="sendByEmail">Send By Email</label>
        <input type="checkbox" className='cursor-pointer mx-3' id="sendByEmail" name="sendByEmail" checked={notificationType?.sendByEmail} onChange={(e) => setNotificationType({...notificationType, sendByEmail: e.target.checked})} />
    </div>
    <div className="col mt-3">
        <label htmlFor="sendBySMS">Send By SMS</label>
        <input type="checkbox" className='cursor-pointer mx-3' id="sendBySMS" name="sendBySMS" checked={notificationType?.sendBySMS} onChange={(e) => setNotificationType({...notificationType, sendBySMS: e.target.checked})} />
    </div>
    <div className="col mt-3">
        <label htmlFor="sendByPush">Send By Push</label>
        <input type="checkbox" className='cursor-pointer mx-3' id="sendByPush" name="sendByPush" checked={notificationType?.sendByPush} onChange={(e) => setNotificationType({...notificationType, sendByPush: e.target.checked})} />
    </div>
    <div className="col mt-3">
        <label htmlFor="sendByWhatsapp">Send By WhatsApp</label>
        <input type="checkbox" className='cursor-pointer mx-3' id="sendByWhatsapp" name="sendByWhatsapp" checked={notificationType?.sendByWhatsapp} onChange={(e) => setNotificationType({...notificationType, sendByWhatsapp: e.target.checked})} />
    </div>
    </div>
    <div className="form-group mt-3">
        <label htmlFor="tygoEventId">Tygo Event</label>
        <select className="form-select mt-2" id="tygoEventId" name="tygoEventId" value={notificationType?.tygoEventId} onChange={(e) => setNotificationType({...notificationType, tygoEventId: parseInt(e.target.value)})}>
            {events.map((event) => (
                <option key={event.id} value={event.id}>{event.name}</option>
            ))}
          </select>
          
        {/* <input type="number" className="form-control mt-2" id="tygoEventId" name="tygoEventId" value={notificationType?.tygoEventId} onChange={(e) => setNotificationType({...notificationType, tygoEventId: parseInt(e.target.value)})} /> */}
    </div>
    

      
      <button type="submit" className="btn btn-primary mt-3 w-100">Submit</button>
    </form>
  )
}

   
export default CreateTypeForm





