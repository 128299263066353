import axios from 'axios'
import {useEffect, useState} from 'react'

interface MicroCheck {
  name: string
  status: string
}

const HealthCheck = () => {
  // const testCall = 'test..tygo.net/health'
  const [selectedMicroservice, setSelectedMicroservice] = useState('')
  const [microservicesCheck, setMicroServicesCheck] = useState<MicroCheck[]>([])

  const microservices = [
    'PrepaidCards',
    'ESIM',
    'Accounts',
    'Payments',
    'Providers',
    'Notifications',
    'AirAlo',
    'RedBull',
    'Compare', // NOT working
    // 'Tygo', // NOT working
    // 'Channels', // NOT working
    // 'Devices', // NOT working
    // 'PaymentWebPage', // NOT working
    'Reviews',
    'Salam',
    'SIM',
  ]

  useEffect(() => {
    // console.log('microservices:', microservices)
    checkAllMicros()
  }, [])

  const checkAllMicros = () => {
    setMicroServicesCheck([])
    microservices.forEach((microName) => {
      axios
        .get(`https://${microName}.tygo.net/health`)
        .then((res) => {
          // console.log(`${microName} with success api call`)
          // console.log('check result:', res)
          setMicroServicesCheck((microservicesCheck) => [
            ...microservicesCheck,
            {name: microName, status: res.data},
          ])
        })
        .catch((error) => {
          setMicroServicesCheck((microservicesCheck) => [
            ...microservicesCheck,
            {name: microName, status: 'NOT implemented'},
          ])
          console.log(`${microName} with failure api call`)
          console.error('error:', error)
        })

      // setHealthStatus(res.data)
    })
  }

  const checkMicro = (microName: string) => {
    axios
      .get(`https://${microName}.tygo.net/health`)
      .then((res) => {
        console.log(`${microName} with success api call`)
        console.log('res:', res)
        setMicroServicesCheck((microservicesCheck) =>
          microservicesCheck.map((ms) => {
            if (ms.name === microName) return {name: ms.name, status: res.data}
            else return ms
          })
        )
      })
      .catch((error) => {
        console.log(`${microName} with failure api call`)
        console.error('error:', error)
      })
  }

  // console.log('microservicesCheck:', microservicesCheck)

  return (
    <div className='container'>
      <h1 className='text-center my-5'>Health Check Page</h1>
      {/* <button className='btn btn-secondary btn-lg btn-block mb-3 fs-1' style={{width: '100%'}}>
        Check all Microservices Health
      </button> */}

      <div className='row'>
        {microservicesCheck.length > 0 &&
          microservicesCheck.map((micro, index) => (
            <div className='col-md-4' key={index}>
              <div className=' mb-3'>
                <div className='d-flex flex-column align-items-center m-10'>
                  <h5
                    className='fs-4 mb-3 text-center cursor-pointer'
                    style={{width: '100%'}}
                    onClick={() => {
                      checkMicro(micro.name)
                    }}
                  >
                    {micro.name} Microservice
                  </h5>
                  <p
                    className={`rounded-circle p-4 ${
                      micro.status === 'Healthy'
                        ? 'bg-success'
                        : micro.status === 'NOT implemented'
                        ? 'bg-warning'
                        : 'bg.danger'
                    }`}
                  ></p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default HealthCheck
