import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateTypeForm from '../components/CreateType';
import CreateNotificationContentForm from '../components/CreateNotificationContent';
import NotifcationType from '../../../../models/NotificationType';
import NotificationService from '../../../../services/notification.service';
import NotificationType from '../../../../models/NotificationType';
import { KTSVG } from '../../../../_metronic/helpers';
import { useNavigate, useParams } from 'react-router-dom';

const NotificationsTypesScreen: React.FC = () => {
    const params = useParams();
    const [id, setId] = React.useState<number>(params.id ? Number(params.id) : 0);

         const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [loading, setLoading] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState<NotificationType | null>(null);

    const [types, setTypes] = React.useState<NotificationType[]>([])
    const [event, setEvent] = React.useState("")
    const fetchEvents = async () => {
        if (id) {
            const response = await NotificationService.getTypesByEvent(id);
            setEvent(response.data.name)
            setTypes(response.data.notificationTypes)
        } else 
        {const events_ = await NotificationService.getEvents();
        const response = await NotificationService.getTypes();
        response.data.forEach((type: NotificationType) => {
            type.tygoEvent = events_.data.find((event: NotificationType) => event.id === type.tygoEventId)
        }
        )
        console.log(response.data)
        setTypes(response.data)}
    }
    React.useEffect(() => {
        fetchEvents()
    }, [id, params]);
    const nav = useNavigate();
    const goToNotificationContent = (id: number) => {
        nav(`/notifications/content/${id}`)
    }

    const handleSubmit = async (notificationType: NotificationType) => {
        setLoading(true);
        if (selectedType) {
            const bool = (await NotificationService.updateNotifcationType(notificationType)).data;
            if (bool) {
                fetchEvents()
                handleClose()
            }
        } else {
            const bool = (await NotificationService.addType(notificationType)).data;
            if (bool) {
                fetchEvents()
                handleClose()
            }
        }   
        setLoading(false);
        
    }

    const goToAll = () => {
        window.location.href = '/notifications/types'
    }

    return (
        <div className={`card`}>
        {/* <Button variant="primary" onClick={handleShow}>
            Add Notification Type
        </Button> */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-4'>Notifications Types {event !== "" ? `- ${event}` : ''}</span>
          </h3>
          {/* create btn */}

          <div className='d-flex' style={{position: 'absolute', right: '22px'}}>
            {/* <div className='d-flex align-items-center mr-2'>
          <KTSVG path='/media/icons/duotune/communication/com014.svg' className='svg-icon-1 svg-icon-primary' />
          <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
            {total} Total Users
          </span>
          </div> */}

            <div className='border border-gray-300 border-dashed rounded min-w-125px px-5  '>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen024.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{types.length}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Total Types</div>
            </div>
            <div className='card-toolbar text-end'>
              {/* <Link
                to={'/cards/esim/create'}
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                
              </Link> */}
              <Button variant="primary" onClick={handleShow}>
              <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                </span>
                Create
                </Button>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-900 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead className='bg-gray-100 w-100'>
                  <tr className='border-0 d-flex justify-content-between align-items-center px-3'>
                    <th  style={{width: '200px'}} className='text-start'>
                      Title
                    </th>
                    <th  className='text-start'>
                        notfication Priorty
                    </th>
                    <th  className='text-start'>
                        sendByEmail
                    </th>
                    <th  className='text-start'>
                        sendByPush
                    </th>
                    <th  className='text-start'>
                        sendBySMS
                    </th>
                    {/* actions */}
                    <th  style={{width: '200px'}} className='text-start'>
                      Actions
                    </th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {types.map((type, index) => (
                <tr key={index} className='border-0 d-flex justify-content-between align-items-center px-3' >
                    <td width={200} >
                      <p>
                        {type.title}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {type.notficationPriorty}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {type.sendByEmail ? 'Yes' : 'No'}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {type.sendByPush ? 'Yes' : 'No'}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {type.sendBySMS ? 'Yes' : 'No'}
                      </p>
                    </td>
                    <td>
                        <div className='d-flex gap-3'>
                            <Button variant="primary" onClick={() => {
                                handleShow()
                                setSelectedType(type)
                            }
                            }>
                            
                            Edit
                            </Button>
                            <Button variant="secondary" onClick={()=>goToNotificationContent(type.id)}>
                           
                            Notifications Content
                            </Button>
                        </div>
                    </td>
                   
                  </tr>
                )    
            )    
            }
                 
                  
                </tbody>
                {/* end::Table body */}
                </table>
            </div>
            {/* end::Table */}
            {/* see all */}
            {
                id !== 0 &&
                <div className='d-flex justify-content-center align-items-center'>
                    <Button
                        
                        onClick={() => {goToAll()}}
                        type='button'
                        className='btn btn-light-primary fw-bolder fs-6 px-7 py-3'
                    >
                        See All
                    </Button>
                    </div>
            }
            </div>
            {/* end::Table container */}
            </div>
            {/* end::Tap pane */}
            </div>


        <Modal show={show} onHide={handleClose}size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                {
                /////  selectedPromoCode ? 'Update Promo Code' : 'Add Promo Code'
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <CreateTypeForm init={null} id={0} handleSubmit={function (notifcationType: NotifcationType): void {
                        throw new Error('Function not implemented.');
                    } } /> */}
                    {
                        !loading ?
                  <CreateTypeForm init={
                        selectedType ? selectedType : null
                  } id={0} handleSubmit={(n) => {
                    handleSubmit(n)
                  }
                  }/>: 
                  <div className="d-flex justify-content-center">
                    loading...
                    </div>
                    }
                
                
                {/* <PromoCodeForm init={selectedPromoCode} id={0} handleSubmit={handleSubmit} /> */}
            </Modal.Body>
        </Modal>
        </div>
    );
        
};

export default NotificationsTypesScreen;