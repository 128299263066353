import { REACT_APP_API_PROVIDERS } from "../config";
import axios from "./interceptors/axios.global";

const api = `${REACT_APP_API_PROVIDERS}/lookup`;

const contactsTypes = async () => {
    return axios.get(`${api}/contract-type`);
}

const simTypes = async () => {
    return axios.get(`${api}/sim-type`);
}

const planTypes = async () => {
    return axios.get(`${api}/plan-type`);
}
const providerTypes = async () => {
    return axios.get(`${api}/provider-type`);
}

const supportedNation = async () => {
    return axios.get(`${api}/supported-nation`);
}


const LookUpService = {
    contactsTypes,
    simTypes,
    planTypes,
    providerTypes,
    supportedNation

}

export default LookUpService;
