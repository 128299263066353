import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateTypeForm from '../components/CreateType';
import CreateNotificationContentForm from '../components/CreateNotificationContent';
import NotifcationType from '../../../../models/NotificationType';
import NotificationContent from '../../../../models/NotificationContent';
import NotificationService from '../../../../services/notification.service';
// get id from url
import { useNavigate, useParams } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { bool, boolean } from 'yup';

const NotificationsIndex: React.FC = () => {
    const params = useParams();
    const [id, setId] = React.useState<number>(params.id ? Number(params.id) : 0);
    const [show, setShow] = React.useState(false)

    const handleClose = () => setShow(false)

    const handleShow = () => setShow(true)
    const [loading, setLoading] = React.useState(false)
    const handleSubmit = async (notificationContent: NotificationContent) => {
        setLoading(true);
        NotificationService.addNotificationContent(notificationContent).then((res) => {
        const bool = res.data;
            if (bool) {
                fetchContents()
                handleClose()
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        }
        )
    }


    const [contents, setContents] = React.useState<any>(null);
    const fetchContents = async () => {
        const contents_ = await NotificationService.getNotificationContent(id);
        setContents(contents_.data)
    }
    React.useEffect(() => {
        fetchContents()
    }, []);

    // const nav = useNavigate();
    // const goToNotificationContent = (id: number) => {
    //     nav(`/notifications/content/${id}`)
    // }
    if (!contents) {
        return <div>Loading...</div>
    }
    return (
        <div className={`card`}>
        {/* <Button variant="primary" onClick={handleShow}>
            Add Notification Type
        </Button> */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-4'>{contents.title}</span>
          </h3>
          {/* create btn */}

          <div className='d-flex' style={{position: 'absolute', right: '22px'}}>
            {/* <div className='d-flex align-items-center mr-2'>
          <KTSVG path='/media/icons/duotune/communication/com014.svg' className='svg-icon-1 svg-icon-primary' />
          <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
            {total} Total Users
          </span>
          </div> */}

            <div className='border border-gray-300 border-dashed rounded min-w-125px px-5  '>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen024.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{contents.notifcationContents.length}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Total Notifications Content</div>
            </div>
            <div className='card-toolbar text-end'>
              {/* <Link
                to={'/cards/esim/create'}
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                
              </Link> */}
              <Button variant="primary" onClick={handleShow}>
              <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                </span>
                Create
                </Button>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-900 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead className='bg-gray-100 w-100'>
                  <tr className='border-0 d-flex justify-content-between align-items-center px-3'>
                    <th  style={{width: '200px'}} className='text-start'>
                      Title
                    </th>
                    <th  className='text-start'>
                        header
                    </th>
                    <th  className='text-start'>
                        footer
                    </th>
                    <th  className='text-start'>
                        Notifcation Method
                    </th>
                    <th  className='text-start'>
                        language
                    </th>
                    {/* actions
                    <th  style={{width: '200px'}} className='text-start'>
                      Actions
                    </th> */}
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {contents.notifcationContents.map((content: NotificationContent, index: number) => (
                <tr key={index} className='border-0 d-flex justify-content-between align-items-center px-3' >
                    <td width={200} >
                      <p>
                        {content.title}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {content.header}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {content.footer}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {content.notifcationMethod}
                      </p>
                    </td>
                    <td className='fw-semibold text-start'>
                      <p>
                        {content.languageId === 2 ? 'English' : 'Arabic'}
                      </p>
                    </td>
                    {/* <td>
                        <div className='d-flex gap-3'>
                            <Button variant="primary" onClick={handleShow}>
                            <span className='svg-icon svg-icon-3'>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                            </span>
                            Edit
                            </Button>
                            
                        </div>
                    </td> */}
                   
                  </tr>
                )    
            )    
            }
                 
                  
                </tbody>
                {/* end::Table body */}
                </table>
            </div>
            {/* end::Table */}
            </div>
            {/* end::Table container */}
            </div>
            {/* end::Tap pane */}
            </div>


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {
                /////  selectedPromoCode ? 'Update Promo Code' : 'Add Promo Code'
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <CreateTypeForm init={null} id={0} handleSubmit={function (notifcationType: NotifcationType): void {
                        throw new Error('Function not implemented.');
                    } } /> */}
                    {
                        !loading ?
                  <CreateNotificationContentForm init={null} id={0} handleSubmit={(n) => {
                    handleSubmit(n)
                  }
                  }/>: 
                  <div className="d-flex justify-content-center">
                    loading...
                    </div>
                    }
                
                
                {/* <PromoCodeForm init={selectedPromoCode} id={0} handleSubmit={handleSubmit} /> */}
            </Modal.Body>
        </Modal>
        </div>
    );
};

export default NotificationsIndex;