import {REACT_APP_API_PROMO} from '../config'
import PromoCode from '../models/PromoCode'
import axios from './interceptors/axios.global'
const api = `${REACT_APP_API_PROMO}/v2`



// GET
// ​/api​/v{version}​/PromoCode​/GetAllPromoCode
// ‍Admin 🔧

// GET
// ​/api​/v{version}​/PromoCode​/GetPromoCodeById​/Id
// ‍Admin 🔧

// POST
// ​/api​/v{version}​/PromoCode​/CreatePromoCode
// ‍Admin 🔧

// PUT
// ​/api​/v{version}​/PromoCode​/Update
// ‍Admin 🔧

// GET
// ​/api​/v{version}​/PromoCode​/GetPromoDataByCode

const create = async (data: PromoCode) => {
  return axios.post(`${api}/PromoCode/CreatePromoCode`, data)
}

const update = async (data: PromoCode) => {
  return axios.put(`${api}/PromoCode/Update`, data)
}

const getById = (id: number) => {
  return axios.get(`${api}/PromoCode/GetPromoCodeById/${id}`)
}

const getAll = () => {
  return axios.get(`${api}/PromoCode/GetAllPromoCode`)
}





const PromoCodeService = {
  create,
  update,
  getById,
  getAll,
}
export default PromoCodeService
