/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import { Modal } from 'react-bootstrap'
import NotificationContent from '../../../../models/NotificationContent'
import NotifcationService from '../../../../services/notification.service'
import NotificationType from '../../../../models/NotificationType'
import NotificationService from '../../../../services/notification.service'
// import { Language } from 'prism-react-renderer'
// form 
const CreateNotificationContentForm = (
  {
    init,
    id,
    handleSubmit,
  }: {
    init: NotificationContent | null,
    id: number,
    handleSubmit: (notificationContent: NotificationContent) => void
  }
) => {
const [notificationContent, setNotificationContent] = React.useState<NotificationContent>({
    ...init,
    id: 0,
    title: '',
    content: '',
    header: '',
    footer:'',
    links:'',
    templateURL:'',
    notifcationMethod:'1',
    notificationTypeId:0,
    languageId:1,
})

const formattedDate = (date: string) => {
    const d = new Date(date)
    const year = d.getFullYear()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
}
  
  React.useEffect(() => {
    if(!init && id)
    fetchNotificationContent()
    else if(init)
    {
      setNotificationContent({
        ...init,
      })
    }
    fetchTypes();
  }, [id, notificationContent])
  const fetchNotificationContent = async () => {
    const response = await NotifcationService.getNotificationContent(id)
    setNotificationContent(response.data)
  }

  const [types, setTypes] = React.useState<NotificationType[]>([]);
  const fetchTypes = async () => {
      const response = await NotificationService.getTypes();
      setTypes(response.data)
      // notificationTypeId = first type
      if (notificationContent.notificationTypeId === 0) {
        setNotificationContent({...notificationContent, notificationTypeId: response.data[0].id})
      }
    }
  

  

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      handleSubmit(notificationContent)
    }}>

      <div className="form-group mt-3">
        <label htmlFor="title">Title</label>
        <input type="text" className="form-control mt-2" id="title" name="title" value={notificationContent?.title} onChange={(e) => setNotificationContent({...notificationContent, title: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="content">Content</label>
        <input type="text" className="form-control mt-2" id="content" name="content" value={notificationContent?.content} onChange={(e) => setNotificationContent({...notificationContent, content: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="header">Header</label>
        <input type="text" className="form-control mt-2" id="header" name="header" value={notificationContent?.header} onChange={(e) => setNotificationContent({...notificationContent, header: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="footer">Footer</label>
        <input type="text" className="form-control mt-2" id="footer" name="footer" value={notificationContent?.footer} onChange={(e) => setNotificationContent({...notificationContent, footer: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="links">Links</label>
        <input type="text" className="form-control mt-2" id="links" name="links" value={notificationContent?.links} onChange={(e) => setNotificationContent({...notificationContent, links: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="templateURL">Template URL</label>
        <input type="text" className="form-control mt-2" id="templateURL" name="templateURL" value={notificationContent?.templateURL} onChange={(e) => setNotificationContent({...notificationContent, templateURL: e.target.value})} />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="notifcationMethod">Notifcation Method</label>
        <select className="form-control mt-2" id="notifcationMethod" name="notifcationMethod" value={notificationContent?.notifcationMethod} onChange={(e) => setNotificationContent({...notificationContent, notifcationMethod: e.target.value})}>
          <option value="1">Email</option>
          <option value="2">SMS</option>
          <option value="3">Push</option>
          <option value="4">InApp</option>
        </select>

      </div>
      <div className="form-group mt-3">
        <label htmlFor="notificationTypeId">Notification Type</label>
        <select className="form-control mt-2" id="notificationTypeId" name="notificationTypeId" value={notificationContent?.notificationTypeId} onChange={(e) => setNotificationContent({...notificationContent, notificationTypeId: parseInt(e.target.value)})}>
          {types.map((type) => (
            <option value={type.id}>{type.title}</option>
          ))}
        </select>
      </div>
      <div className="form-group mt-3">
        <label htmlFor="languageId">Language</label>
        <select className="form-control mt-2" id="languageId" name="languageId" value={notificationContent?.languageId} onChange={(e) => setNotificationContent({...notificationContent, languageId: parseInt(e.target.value)})}>
          <option value="1">Arabic</option>
          <option value="2">English</option>
        </select>
      </div>
      <button type="submit" className="btn btn-primary mt-3 w-100">Submit</button>
    </form>
  )
}

   
export default CreateNotificationContentForm





