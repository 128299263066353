/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import { Modal } from 'react-bootstrap'
import NotificationEvent from '../../../../models/NotificationEvent'
import NotificationEventService from '../../../../services/promo.service'
// form 
const CreateEventForm = (
  {
    init,
    id,
    handleSubmit,
  }: {
    init: NotificationEvent | null,
    id: number,
    handleSubmit: (notificationEvent: NotificationEvent) => void
  }
) => {
const [notificationEvent, setNotificationEvent] = React.useState<NotificationEvent>({
    ...init,
    id: 0,
    name: ''
})

const formattedDate = (date: string) => {
    const d = new Date(date)
    const year = d.getFullYear()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
}
  
  React.useEffect(() => {
    if(!init && id)
    fetchNotificationEvent()
    else if(init)
    {
      setNotificationEvent({
        ...init,
        // startdate: formattedDate(init.startdate),
        // enddate: formattedDate(init.enddate)
      })
    }


  }, [id, notificationEvent])
  
  const fetchNotificationEvent = async () => {
    const response = await NotificationEventService.getById(id);
    setNotificationEvent(response.data)
  }
  
  // submit form
  

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      handleSubmit(notificationEvent)
    }}>

      <div className="form-group mt-3">
        <label htmlFor="name">Name</label>
        <input type="text" className="form-control mt-2" id="name" name="name" value={notificationEvent?.name} onChange={(e) => setNotificationEvent({...notificationEvent, name: e.target.value})} />
      </div>
      <button type="submit" className="btn btn-primary mt-3 w-100">Submit</button>
    </form>
  )
}

   
export default CreateEventForm





