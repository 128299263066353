import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {List} from './components/List'
import {Form} from './components/Form'
import NotificationsIndex from './pages/NotificationsIndex'
import NotificationsTypesScreen from './pages/NotificationsTypesScreen'
import NotificationsEventsScreen from './pages/NotificationsEventsScreen'
// import {Settings} from './components/settings/Settings'
// import {NotificationsWrapperHeader} from './NotificationsWrapperHeader'

const notificationswrapperBreadCrumbs: Array<PageLink> = [
  {
    title: 'Notifications',
    path: '/notifications/types',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const NotificationsWrapperWrapper: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='events'
          element={
            <>
              <PageTitle breadcrumbs={notificationswrapperBreadCrumbs}>Events</PageTitle>
              <NotificationsEventsScreen />
            </>
          }
        />
        <Route
          path='types'
          element={
            <>
            <PageTitle breadcrumbs={notificationswrapperBreadCrumbs}>Types</PageTitle>
              <NotificationsTypesScreen />
            </>
          }
        />

        <Route
          path='types/:id'
          element={
            <>
            <PageTitle breadcrumbs={notificationswrapperBreadCrumbs}>Types</PageTitle>
              <NotificationsTypesScreen />
            </>
          }
        />
        <Route
          //with id
          path='content/:id'
          element={
            <>
              <PageTitle breadcrumbs={notificationswrapperBreadCrumbs}>Content</PageTitle>
              <NotificationsIndex />
            </>
          }
        />

        {/* <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Edit Profile</PageTitle>
              <Form />
            </>
          }
        /> */}
        {/* User(userName: string, id: string, email: string, emailConfirmed: boolean, firstName: string, lastName: string, phoneNumberConfirmed: boolean, phoneNumber: string, countryCode: string, dateOfBirth: string, createdAt: string): User */}

        {/* <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Create</PageTitle>
              <Create
                user={new User('', '', '', false, '', '', false, '', '', '', '')}
                responseHandler={(res: any) => {
                  console.log(res)
                }}
              />
            </>
          }
        /> */}
       

        {/* <Route index element={<Navigate to='/accounts/dashboard' />} /> */}

        {/* <Route
          path=':id/*'
          element={
            <>
              <AccountHeader />
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              {
                <Routes>
                  <Route path='orders' element={<Orders />} />
                  <Route path='orders_esim' element={<OrdersEsim />} />
                  <Route path='payment' element={<PaymentList />} />
                  <Route path='ordersManagement' element={<SimOrders />} />
                  <Route path='*' element={<Profile />} />
                </Routes>
              }
            </>
          }
        /> */}
      </Route>
    </Routes>
  )
}

export default NotificationsWrapperWrapper
