import {da} from 'date-fns/locale'
import {REACT_APP_API_ACCOUNTS, REACT_APP_API_PROVIDERS} from '../config'
import Country from '../models/Country'
import Provider from '../models/Provider'
import axios from './interceptors/axios.global'
const authControllers = 'AuthControllers'
const admin = 'Admin'
const user = 'User'
const api = `${REACT_APP_API_PROVIDERS}/provider`
const apiPlan = `${REACT_APP_API_PROVIDERS}/plan`

const create = async (data: Provider) => {
  console.log(data)
  const formData = new FormData()
  formData.append('NameAr', data.NameAr)
  formData.append('NameEn', data.NameEn)
  formData.append('DescriptionAr', data.DescriptionAr)
  formData.append('DescriptionEn', data.DescriptionEn)
  formData.append('ImagePathLarge', data.ImagePathLarge)
  formData.append('ImagePathSmall', data.ImagePathSmall)
  formData.append('ProviderTypeId', data.ProviderTypeId.toString())
  formData.append('IsVisible', data.isVisible == '1' ? 'true' : 'false')
  data.ContarctTypeIds.forEach((element) => {
    formData.append('ContarctTypeIds', element)
  })
  formData.append('PriorityKey', data.PriorityKey.toString())
  formData.append('PrimaryColor', data.PrimaryColor)
  formData.append('SecondaryColor', data.SecondaryColor)
  return axios.post(`${api}`, formData)
}

const update = async (data: Provider) => {
  console.log(data)
  const formData = new FormData()
  formData.append('NameAr', data.NameAr)
  formData.append('NameEn', data.NameEn)
  formData.append('DescriptionAr', data.DescriptionAr)
  formData.append('DescriptionEn', data.DescriptionEn)
  formData.append('ImagePathLarge', data.ImagePathLarge)
  formData.append('ImagePathSmall', data.ImagePathSmall)
  formData.append('ProviderTypeId', data.ProviderTypeId.toString())
  formData.append('IsVisible', data.isVisible == '1' ? 'true' : 'false')
  data.ContarctTypeIds.forEach((element) => {
    formData.append('ContarctTypeIds', element)
  })
  formData.append('PriorityKey', data.PriorityKey.toString())
  formData.append('PrimaryColor', data.PrimaryColor)
  formData.append('SecondaryColor', data.SecondaryColor)
  return axios.put(`${api}/${data.id}`, formData)
}

const changeVisibility = (id: string) => {
  return axios
    .put(`${api}/${id}/change-visible-status`)
    .then((res) => {
      return res.data.returnData.IsVisible
    })
    .catch((err) => {
      console.log(err)
    })
}

const getActiveContractTypes = (id: string) => {
  return axios
    .get(`${api}/${id}/active-contract-types`)
    .then((res) => {
      return res.data.returnData
    })
    .catch((err) => {
      console.log(err)
    })
}

const getById = (id: string) => {
  return axios.get(`${api}/${id}`)
}

const getProfile = () => {
  return axios.get(`${REACT_APP_API_ACCOUNTS}/${user}/GetProfile`)
}

const getAll = (PageNumber: number, PageSize: number) => {
  return axios.get(`${api}`)
}

const delete_ = (id: string) => {
  return axios.delete(`${api}/${id}`)
}

const getCounriesWithESim = () => {
  return axios.get(`${REACT_APP_API_PROVIDERS}/Country/has-esim`)
}

const getCountryEsims = (countryId: number) => {
  return axios.get(
    `${REACT_APP_API_PROVIDERS}/plan/customer/esim/pagination?countryId=${countryId}&offset=1&limit=10`
  )
}

const getCountries = () => {
  return axios.get(`${REACT_APP_API_PROVIDERS}/Country`)
}

const getCountryById = (id: string) => {
  return axios.get(`${REACT_APP_API_PROVIDERS}/Country/${id}`)
}

const createCountry = (data: any) => {
  //make all keys start with capital letter
  data = Object.keys(data).reduce((acc: any, key) => {
    acc[key.charAt(0).toUpperCase() + key.slice(1)] = data[key]
    return acc
  }, {})

  data.PrimaryColor = data.PrimaryColor.replace('#', '')
  data.SecondaryColor = data.SecondaryColor.replace('#', '')

  // to form data
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  formData.append('CountryImageURL', data.FlagImageURL)

  if (!data.Id) return axios.post(`${REACT_APP_API_PROVIDERS}/Country`, formData)
  else return axios.put(`${REACT_APP_API_PROVIDERS}/Country/` + data.Id, formData)
}

const updateCountry = (data: Country) => {
  return axios.put(`${REACT_APP_API_PROVIDERS}/Country/${data.id}`, data)
}

const deleteCountry = (id: string) => {
  return axios.delete(`${REACT_APP_API_PROVIDERS}/Country/${id}`)
}

const getPlanById = (id: string) => {
  return axios.get(`${apiPlan}/${id}/customer`)
}

const ProviderService = {
  create,
  getActiveContractTypes,
  changeVisibility,
  update,
  delete: delete_,
  getAll,
  getById,
  getCounriesWithESim,
  getProfile,
  //Country
  getCountries,
  getCountryById,
  createCountry,
  updateCountry,
  deleteCountry,
  getCountryEsims,
  getPlanById,
}
export default ProviderService
