import {REACT_APP_API_NOTIFICATIONS} from '../config'
import PromoCode from '../models/PromoCode'
import axios from './interceptors/axios.global'
const api = `${REACT_APP_API_NOTIFICATIONS}/Configuration`




const getEvents = () => {
  return axios.get(`${api}/GetListOfTygoEvents`)
}
// curl -X POST "https://test.notifications.tygo.net:443/api/Configuration/AddTygoEvent" -H  "accept: text/plain" -H  "Authorization: Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjNhNTE0NjM1LWIzMGYtNGJjNi04YTE0LTI5ZDI5ZjU3OTBjMyIsImFwcC1pZCI6IlR5Z29XZWIiLCJjbGllbnQtaWQiOiJUeWdvQXBwIiwiU2VjdXJpdHlTdGFtcCI6IklWQlNaV08yWEUyV1NVSVVFVE5DSjUyRVlLWklIN05BIiwiZmlyc3ROYW1lIjoiTm91cmVkZGRpbmUiLCJsYXN0TmFtZSI6IiIsIm5hbWUiOiJOb3VyZWRkZGluZSIsInBob25lTnVtYmVyIjoiIiwibmJmIjoiMTcxNzMzODI2NCIsImV4cCI6IjE3MTk5MzAyNjQiLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo1MDAxIiwiYXVkIjoiVGF5Z29BcGkiLCJEZXZpY2VUeXBlIjoiV2ViIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjpbIkFkbWluIiwiRmluYW5jZSJdfQ.xLm59ci03WOAHhZXfQllCz2zucHAIC8m_eyeXgCRD5M" -H  "Content-Type: application/json-patch+json" -d "{\"name\":\"string\"}"

const addEvents = (data: any) => {

  return axios.post(`${api}/AddTygoEvent`, data)
}

const addType = (data: any) => {
  return axios.post(`${api}/AddNotifcationType`, data)
}

const getTypes = () => {
  return axios.get(`${api}/GetNotificationTypes`)
}

const getTypesByEvent = (id: number) => {
  return axios.get(`${api}/GetTygoEventbyId?id=${id}`)
}

const getNotificationContent = (id: number) => {
  return axios.get(`${api}/GetNotificationTypeWithContent?id=${id}`)
}

const addNotificationContent = (data: any) => {
  return axios.post(`${api}/AddNotifcationContent`, data)
}

const updateNotifcationType = (data: any) => {
  return axios.post(`${api}/UpdateNotifcationType`, data)
}


const NotificationService = {
  getEvents,
  addEvents,
  addType,
  getTypes,
  getNotificationContent,
  addNotificationContent,
  getTypesByEvent,
  updateNotifcationType
}
export default NotificationService
