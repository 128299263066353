const REACT_APP_API_ACCOUNTS: string = window.REACT_APP_API_ACCOUNTS || ''
const REACT_APP_API_PROVIDERS: string = window.REACT_APP_API_PROVIDERS || ''
const REACT_APP_API_PREPAID: string = window.REACT_APP_API_PREPAID || ''
const REACT_APP_API_ESIM: string = window.REACT_APP_API_ESIM || ''
const REACT_APP_API_PAYMENTS: string = window.REACT_APP_API_PAYMENTS || ''
const REACT_APP_API_PROMO: string = window.REACT_APP_API_PROMO || ''
const REACT_APP_API_SIM: string = window.REACT_APP_API_SIM || ''
const REACT_APP_API_IDENTITY: string = window.REACT_APP_API_IDENTITY || ''
const REACT_APP_API_NOTIFICATIONS: string = window.REACT_APP_API_NOTIFICATIONS || ''

export {
  REACT_APP_API_ACCOUNTS,
  REACT_APP_API_PROVIDERS,
  REACT_APP_API_PREPAID,
  REACT_APP_API_ESIM,
  REACT_APP_API_PAYMENTS,
  REACT_APP_API_PROMO,
  REACT_APP_API_SIM,
  REACT_APP_API_IDENTITY,
  REACT_APP_API_NOTIFICATIONS
}
