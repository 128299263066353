import React, { useEffect } from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers/components/KTSVG'
import { Modal } from 'react-bootstrap'
import ProviderService from '../../../../services/provider.service'
import LookUpService from '../../../../services/lookup.service'
import CountryService from '../../../../services/countries.service'
import MultiRangeSlider from './page-title/RangeMulti'
import PlanService from '../../../../services/plan.service'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

export function MenuInner() {
  const intl = useIntl()
  const [search, setSearch] = React.useState('')
  const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Search
  const [searchType, setSearchType] = React.useState('plans');

  //load plan options
  const [providers, setProviders] = React.useState([]);
  const [planTypes, setPlanTypes] = React.useState([]);
  const [simTypes, setSimTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [contactsTypes, setContactsTypes] = React.useState([]);
  const [supportedNations, setSupportedNations] = React.useState([]);

  const [searchData, setSearchData] = React.useState<any>({
    // providerIds: [],
    providersIds: [],
    planTypeIds: [],
    contractTypeIds: [],
      
  })
  const navigator = useNavigate();

  const getPlanOptions = async () => {
    setLoading(true);
    if (providers.length === 0) {
      const providers = await ProviderService.getAll(0, 0).then(res => {
        return res.data.returnData;
      }
      );
      setProviders(providers);
    }
    if (planTypes.length === 0) {
      const planstypes = await LookUpService.planTypes().then(res => {
        return res.data.returnData;
      }
      );
      setPlanTypes(planstypes);
    }
    if(contactsTypes.length === 0){
      const contactsTypes = await LookUpService.contactsTypes().then(res => {
        return res.data.returnData;
      }
      );
      setContactsTypes(contactsTypes);
    }

    if (simTypes.length === 0) {
      const simtypes = await LookUpService.simTypes().then(res => {
        return res.data.returnData;
      }
      );
      setSimTypes(simtypes);
    }
    if (countries.length === 0) {
      const countries = await CountryService.getAllCountries().then(res => {
        return res.data.returnData;
      }
      );
      setCountries(countries);
    }
    if (supportedNations.length === 0) {
      const supportedNations = await LookUpService.supportedNation().then(res => {
        return res.data.returnData;
      }
      );
      setSupportedNations(supportedNations);
    }
    setLoading(false);
  }

  const searchPlans = async () => {    
    const searchparam = JSON.stringify(searchData);
    navigator('/plans/list?search=' + searchparam);
    setShowAdvancedSearch(false);
    
    // window.location.href = `/plans/list?search=${searchparam}`;
  }

  useEffect(() => {
    if(searchType === 'plans' && showAdvancedSearch)
      getPlanOptions();
  }, [showAdvancedSearch]);


  return (
    <>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-450px ps-14'
          placeholder='Search Accounts, Operators, etc...'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* button */}
        <button
          type='button'
          className='btn btn-sm btn-primary btn-active-light-primary position-absolute top-50 end-0 translate-middle-y me-1'
          onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
        >
          Advanced Search
        </button>
        <Modal show={showAdvancedSearch} onHide={() => setShowAdvancedSearch(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Advanced Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='card-toolbar mb-4 '>
              <ul className='nav m-auto' style={{ width: 'fit-content' }}>
                <li className='nav-item'>
                  <span
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${searchType === 'all' ? 'active' : ''}`}
                    onClick={() => { setSearchType('all') }}
                  >
                    All
                  </span>
                </li>
                <li className='nav-item'>
                  <span
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${searchType === 'providers' ? 'active' : ''}`}
                    onClick={() => { setSearchType('providers') }}
                  >
                    Providers
                  </span>
                </li>
                <li className='nav-item'>
                  <span
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${searchType === 'plans' ? 'active' : ''}`}
                    onClick={() => {
                      setSearchType('plans');
                      getPlanOptions();
                    }}
                  >
                    Plans
                  </span>
                </li>
                <li className='nav-item'>
                  <span
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${searchType === 'cards' ? 'active' : ''}`}
                    onClick={() => { setSearchType('cards') }}
                  >
                    Cards
                  </span>
                </li>

              </ul>
            </div>

            {
              loading ? <div className='text-center'>Loading...</div> :
                searchType === 'plans' &&
                <>
                  <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Plan Type</label>
              <div className='col-lg-8 fv-row'>
              
                
                <Select
                  name="plan Types"
                  placeholder="Select Plan Types"
                  options={planTypes.map((plan: any) => {
                    return { value: plan.id, label: plan.name } as any
                  })}
                  onChange={(e:any) => {
                    setSearchData({...searchData, planTypeIds: e.map((item:any) => item.value)})
                  }}
                  value={planTypes.filter(
                    (plan: any) => searchData.planTypeIds.includes(plan.id)
                    ).map((plan: any) => {
                    return { value: plan.id, label: plan.name } as any
                  })}
                  isMulti
                />
              </div>
              </div>
              <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contarct Type</label>
              <div className='col-lg-8 fv-row'>
              
                
                <Select
                  name="Contarct Type"
                  placeholder="Select Contarct Type"
                  options={contactsTypes.map((contract: any) => {
                    return { value: contract.id, label: contract.name } as any
                  })}
                  onChange={(e:any) => {
                    setSearchData({...searchData, contractTypeIds: e.map((item:any) => item.value)})
                  }}
                  value={contactsTypes.filter(
                    (contract: any) => searchData.contractTypeIds.includes(contract.id)
                    ).map((contract: any) => {
                    return { value: contract.id, label: contract.name } as any
                  })}
                  isMulti
                />
              </div>
              </div>
              <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Providers</label>

              <div className='col-lg-8 fv-row'>
              
                
                <Select
                  name="Providers"
                  placeholder="Select Providers"
                  options={providers.map((provider: any) => {
                    return { value: provider.id, label: provider.nameEn } as any
                  })}
                  onChange={(e:any) => {
                    setSearchData({...searchData, providersIds: e.map((item:any) => item.value)})
                  }}
                  value={providers.filter(
                    (provider: any) => searchData.providersIds.includes(provider.id)
                    ).map((provider: any) => {
                    return { value: provider.id, label: provider.nameEn } as any
                  })}
                  isMulti
                />
              </div>
              </div>
                  {/* <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Plan Name</label>
                      <div className='col-lg-8 fv-row'>
                      <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid '
                      placeholder='Plan Name'
                      value={searchData.name}
                      onChange={(e) => setSearchData({ ...searchData, name: e.target.value })}
                    />
                      </div>
                    </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Price Range</label>
                    <div className='col-lg-8 fv-row'>
                    <MultiRangeSlider
                      min={0}
                      max={9999}
                      step={100}
                      onChange={({ min, max }: any) => {
                        const data = searchData;
                        data.priceFrom = min;
                        data.priceTo = max;
                        setSearchData(data);
                      }}
                    />
                    </div>
                  </div> */}
                  {/* {
                    providers.length > 0 &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Provider</label>
                      <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid'
                          value={searchData.providerId}
                          onChange={(e) => {
                            const data = searchData;
                            data.providerId = e.target.value;
                            setSearchData(data);
                          }}
                        >
                          <option value=''>All</option>
                          {
                            providers.map((provider, index) => {
                              const { id, nameEn } = provider;
                              return <option key={index} value={id}>{nameEn}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  }
                  {
                    planTypes.length > 0 &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Plan Type</label>
                      <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid'
                          value={searchData.planTypeId}
                          onChange={(e) => {
                            const data = searchData;
                            data.planTypeId = e.target.value;
                            setSearchData(data);
                          }} 
                        >
                          <option value=''>All</option>
                          {
                            planTypes.map((planType, index) => {
                              const { id, name } = planType;
                              return <option key={index} value={id}>{name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  }
                  {
                    simTypes.length > 0 &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Sim Type</label>
                      <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid'
                          value={searchData.simTypeId}
                          onChange={(e) => {
                            const data = searchData;
                            data.simTypeId = e.target.value;
                            setSearchData(data);
                          }} 
                        >
                          <option value=''>All</option>
                          {
                            simTypes.map((simType, index) => {
                              const { id, name } = simType;
                              return <option key={index} value={id}>{name}</option>
                            })
                          }
                        </select>
                      </div>

                    </div>
                  }
                  {
                    supportedNations.length > 0 &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Supported Nation</label>
                      <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid'
                          value={searchData.supportedNationId}
                          onChange={(e) => {
                            const data = searchData;
                            data.supportedNationId = e.target.value;
                            setSearchData(data);
                          }} 
                        >
                          <option value=''>All</option>
                          {
                            supportedNations.map((supportedNation, index) => {
                              const { id, name } = supportedNation;
                              return <option key={index} value={id}>{name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  }
                  {
                    countries.length > 0 &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Country</label>
                      <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid'
                          value={searchData.countryId}
                          onChange={(e) => {
                            const data = searchData;
                            data.countryId = e.target.value;
                            setSearchData(data);
                          }}
                        >
                          <option value=''>All</option>
                          {
                            countries.map((country, index) => {
                              const { id, nameEn } = country;
                              return <option key={index} value={id}>{nameEn}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  } */}
                  <button className='btn btn-primary' onClick={searchPlans}>Search</button>
                </>

            }
          </Modal.Body>
        </Modal>

      </div>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' />
      <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      > */}
      {/* PAGES */}
      {/* <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub> */}

      {/* ACCOUNT */}
      {/* <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* ERRORS */}
      {/* <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* Widgets */}
      {/* <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'> */}
      {/* PAGES */}
      {/* <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}