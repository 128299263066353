import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ComingSoon} from '../modules/comingsoon/CoomingSoon'
import HealthCheck from '../pages/healthCheck'
import OrdersManagement from '../pages/OrdersManagement/components/dashboard'
import OrdersManagementWrapper from '../pages/OrdersManagement/OrdersManagementWrapper'
import NotificationsWrapper from '../pages/notifications/NotificationsWrapper'



const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/_accounts/AccountPage'))
  const AccountsWrapper = lazy(() => import('../pages/accounts/AccountsWrapper'))
  const OperatorsWrapper = lazy(() => import('../pages/operators/OperatorsWrapper'))
  const PlansWrapper = lazy(() => import('../pages/plans/PlansWrapper'))
  const CardsWrapper = lazy(() => import('../pages/cards/CardsWrapper'))
  const PaymentWrapper = lazy(() => import('../pages/payment/PaymentWrapper'))
  const ConfigurationsWrapper = lazy(() => import('../pages/configurations/ConfigurationsWrapper'))
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const AiraloWrapper = lazy(() => import('../pages/airalo/AiraloWrapper'))
  const PromoCodeWrapper = lazy(() => import('../pages/promo/PromoCodeWrapper'))
  const AuthorizationWrapper = lazy(() => import('../pages/authorization/AuthorizationWrapper'))
  const WidgetsPage = lazy(() => import('../modules/widgets_/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AdvertisementWrapper = lazy(() => import('../pages/advertisement/AdvertisementWrapper'))
  const ReportsWrapper = lazy(() => import('../pages/reports/ReportsWrapper'))

  const [roles, setRoles] = useState([])

  const roles_list = [
  {
    name: 'ADMIN',
    routes: [
      '/*',
      '/dashboard/*',
      '/dashboard' // Add this to match exactly /dashboard
    ],
    defaultRoute: '/dashboard'
  },
  {
    name: 'ORDER MANAGEMENT',
    routes: [
      '/ordersManagement/*',
      '/reports/*',

    ],
    defaultRoute: '/ordersManagement/dashboard'
  },
  {
    name: 'FINANCE',
    routes: [
      '/payments/*',
      '/payment/*',
      '/dashboard/*',
      '/dashboard' // Add this to match exactly /dashboard
    ],
    defaultRoute: '/payments/dashboard'
  },
  {
    name: 'CUSTOMER_SERVICE',
    routes: [
      '/accounts/*',
      '/airalo/*',
      '/cards/*',
      '/payment/*',
      '/payments/*',
      '/dashboard/*',
      '/dashboard' // Add this to match exactly /dashboard
    ],
    defaultRoute: '/accounts/dashboard'
  },
  {
    name: 'CONTENT MANAGEMENT',
    routes: [
      '/operators/*',
      '/plans/*',
      '/configurations/*',
      '/notification/*',
      '/dashboard/*',
      '/dashboard' // Add this to match exactly /dashboard
    ],
    defaultRoute: '/operators/dashboard'
  },
  {
    name: 'REPORTING',
    routes: [
      '/ordersManagement/*',
      '/reports/*',
    ],
    defaultRoute: '/ordersManagement/dashboard'
  },
  {
    name: 'SUPER ADMIN',
    routes: [
      '/*',
      '/dashboard/*',
      '/dashboard' // Add this to match exactly /dashboard
    ],
    defaultRoute: '/dashboard'
  }
    // 'FINANCE',
    // 'UI',
    // 'CUSTOMER SERVICE',
    // 'PROVIDER',
    // 'DEVELOPER',
    // 'CONTENT MANAGEMENT',
    // 'PARTNER MANAGEMENT',
    // 'SUPER ADMIN',
    // 'UX',
    // "ACCOUNT MANAGEMENT",
    // "ORDER MANAGEMENT"
  ]
  const checkRoles = (current: string, my_roles: any[]) => {
    let isAllowed = false;
    my_roles.forEach((role) => {
      roles_list.forEach((r) => {
          if (r.name === role.toUpperCase()) {
              r.routes.forEach((route) => {
                  const regexString = route.replace(/\*/g, '.*');
                  const regex = new RegExp('^' + regexString + '$');
                  console.log(`Checking route: ${route} against current: ${current}`);
                  if (regex.test(current)) {
                      console.log(`Matched route: ${route}`);
                      isAllowed = true;
                  }
              });
          }
      });
  });
  return isAllowed;
}
const getDefeualtRoute = (roles_list: any[]) : string => {
  const jwtToken = localStorage.getItem('kt-auth-react-v') || '';

  // Step 2: Decode the Token
  const decodedToken = decodeJWT(jwtToken);
     

  // Step 3: Extract Roles from Decoded Token
    let roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    if(roles_)
    {
      // if roles type string
      if(typeof roles_ === 'string')
      {
        roles_ = [roles_.toUpperCase(),];
      } else
      roles_ = roles_.map((role: string) => role.toUpperCase());
    }
    else 
    {
      roles_ = [];
    }
  

  let defaultRoute = '/dashssboard';
  console.log("my_roles",roles_);
  console.log("roles_list", roles_list);
  roles_.forEach((role: string) => {
      roles_list.forEach((r) => {
          if (r.name.toUpperCase() === role.toUpperCase()) {
              defaultRoute = r.defaultRoute;
          }
      });
  });
  // alert(defaultRoute);
  return defaultRoute;
}
        
  const getRoles = (route :string) => {
      // Step 1: Retrieve JWT from Local Storage
      const jwtToken = localStorage.getItem('kt-auth-react-v') || '';

      // Step 2: Decode the Token
      const decodedToken = decodeJWT(jwtToken);

      // Step 3: Extract Roles from Decoded Token
        let roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
        if(roles_)
        {
          // if roles type string
          if(typeof roles_ === 'string')
          {
            roles_ = [roles_.toUpperCase(),];
          } else
          roles_ = roles_.map((role: string) => role.toUpperCase());
        }
        else 
        {
          roles_ = [];
          alert("you don't have any roles")
          //logout 
          localStorage.removeItem('kt-auth-react-v');
          window.location.href = '/auth/login';
        }
      
        // .filter((role: string) => role !== 'ADMIN');
        // Step 4: Set Roles to State
        setRoles(roles_);
        console.log("roles_", roles_);
        console.log("route", route);
        console.log("checkRoles", checkRoles(route, roles_));
        return checkRoles(route, roles_);
      
      // .filter((role: string) => role !== 'ADMIN');

      // Step 4: Set Roles to State

      // Function to decode JWT token (assuming it's a standard JWT)
      
  }
  function decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}
  const location = useLocation();
  const [authorized, setAuthorized] = useState(true);
  // check each time the route changes
  useEffect(() => {
    if (location.pathname === '/error/403') {
      return;
    }
    setAuthorized(getRoles(location.pathname));
  }, [location.pathname]);
 
  useEffect(() => {
    const element = document.getElementById('kt_content_container')
    if (element) {
      element.classList.remove('container')
      element.classList.add('container-fluid')
    }
  }, [])

  if (!authorized) {
    return<>
      <Navigate to={getDefeualtRoute(roles_list)} />
    </>
    // return <Navigate to='/error/403' />
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={getDefeualtRoute(roles_list)} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='coming-soon' element={<ComingSoon></ComingSoon>} />
        {/* Lazy Modules */}
        {/* <Route
          path='accounts/*'
          element={<TestPage/>}
        /> */}
        {/* <Route path='accounts/add/*' element={
          <SuspensedView>
            <AccountPage />
          </SuspensedView>
        }
        /> */}
        <Route path='healthCheck' element={<SuspensedView>{<HealthCheck />}</SuspensedView>} />
        <Route
          path='ordersManagement/*'
          element={
            <SuspensedView>
              <OrdersManagementWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='accounts/*'
          element={
            <SuspensedView>
              <AccountsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='operators/*'
          element={
            <SuspensedView>
              <OperatorsWrapper />
            </SuspensedView>
          }
        />
        {/* plans */}
        <Route
          path='plans/*'
          element={
            <SuspensedView>
              <PlansWrapper />
            </SuspensedView>
          }
        />
        {/* cards */}
        <Route
          path='cards/*'
          element={
            <SuspensedView>
              <CardsWrapper />
            </SuspensedView>
          }
        />

        {/* airalo */}
        <Route
          path='airalo/*'
          element={
            <SuspensedView>
              <AiraloWrapper />
            </SuspensedView>
          }
        />

        {/* Advertisments */}
        <Route
          path='advertisement/*'
          element={
            <SuspensedView>
              <AiraloWrapper />
            </SuspensedView>
          }
        />

        {/* Reports */}
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsWrapper />
            </SuspensedView>
          }
        />
        
        {/* Notification */}
        <Route
          path='notifications/*'
          element={
            <SuspensedView>
              <NotificationsWrapper />
            </SuspensedView>
          }
        />

        {/* Payment */}

        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentWrapper />
            </SuspensedView>
          }
        />

        {/* Configeration */}
        <Route
          path='configuration/*'
          element={
            <SuspensedView>
              <ConfigurationsWrapper />
            </SuspensedView>
          }
        />

        {/* Supprot */}
        <Route
          path='support/*'
          element={
            <SuspensedView>
              <SupportWrapper />
            </SuspensedView>
          }
        />

        {/* promo */}
        <Route
          path='promo/*'
          element={
            <SuspensedView>
              <PromoCodeWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='authorization/*'
          element={
            <SuspensedView>
              <AuthorizationWrapper />
            </SuspensedView>
          }
        />

        {/* <Route path='accounts/edit/:id' element={<AccountPage />} /> */}
        {/* <Route path='accounts/list' element={<AccountPage />} /> */}

        <Route
          path='crafted/pages/profile/*'
          element={
            <ProfilePage />
            // <SuspensedView>
            //   <ProfilePage />
            // </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
