import axios from 'axios'
import { REACT_APP_API_ACCOUNTS } from '../../../../config'
import {AuthModel, UserModel} from './_models'

const API_URL = REACT_APP_API_ACCOUNTS

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/User/GetProfile`
export const LOGIN_URL = `${API_URL}/AuthControllers/VerifyCode`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/User/SendOtpEmail`
export const REQUEST_OTP_URL = `${API_URL}/AuthControllers/login`


// Server should return AuthModel

export function login(email: string, otpCode: string, otpType: string, deviceType: string, phoneNumber: string, countryCode: string, deviceToken: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    // {
    //   "otpCode": "0723",
    //   "email": "TygoApp@Tygo.com",
    //   "phoneNumber": "",
    //   "countryCode": "",
    //   "deviceToken": "",
    //   "otpType": "Email",
    //   "deviceType": "Web"
    // }
    email,
    otpCode,
    otpType,
    deviceType,
    phoneNumber,
    countryCode,
    deviceToken,
    
  })
}
export function sendOtp(email:string,phoneNumber:string, countryCode:string,logintType:string)
{
  return axios.post<AuthModel>(REQUEST_OTP_URL, {
    email,
    phoneNumber,
    countryCode,
    logintType,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, 
    //set header with token
    { headers: { Authorization: `Bearer ${token}` } }
    
    )
}
