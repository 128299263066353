
import { REACT_APP_API_ACCOUNTS } from '../config';
import axios from './interceptors/axios.global';

const Login = async (username:string, password:string)  => {

    const payload = new FormData();
    payload.append('username', username);
    payload.append('password', password);
    payload.append('grant_type', 'password');
    

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(REACT_APP_API_ACCOUNTS + ':' + REACT_APP_API_ACCOUNTS)
    };
    const api = REACT_APP_API_ACCOUNTS + '/oauth/token';

    return await axios.post(api, payload, { headers }).then(response => {
        if(response.status === 200) {
            const user = {
                access_token: response.data.access_token,
                expires_in: response.data.expires_in,
                jti: response.data.jti,
                organization: response.data.organization,
                refresh_token: response.data.refresh_token,
                scope: response.data.scope,
                token_type: response.data.token_type
            }
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        } else if(response.status === 400) 
        {
            const error = {
                error: response.data.error,
                error_description: response.data.error_description
            }
            return error.error_description;
        } 
        else {
            return 'Error';
        }
    }).catch(error => {
        return error;
    }
    );
}


const Logout = () => {
    localStorage.removeItem('user');
}

const getRole = (user:any) => {
}
const authService = {
    Login,
    Logout,
    getCurrentUser: () => {
        const token = JSON.parse(localStorage.getItem('user')!);
        if (token) {
            return token;
        }
        return null;
    },
    getRole
}
export default authService;
