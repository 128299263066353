import {FC} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  providerID: number
  providerName: string
  imgPath: string
  data: {
    payment_pending: number
    paid: number
    indelivery: number
    waiting_activation: number
    // failed: number
    activated: number
  }
}
const ProviderStats: FC<Props> = ({providerID, providerName, imgPath, data}) => {
  const navigate = useNavigate()

  // console.log('data:',Props.data)
  return (
    <div style={{width: '45%', margin: 10, border: 'none'}}>
      <table
        style={{borderRadius: 15}}
        className='table table-hover m-125 flex-wrap bg-gray-200  align-middle  fs-6 gy-5 dataTable '
      >
        <tr className='text-center text-muted fw-bolder fs-6 gs-0'>
          <th rowSpan={2}>
            <img
              onClick={() =>
                navigate(
                  `/ordersManagement/providers/${providerID}?imagePathSmall=${encodeURIComponent(
                    imgPath
                  )}`
                )
              }
              className='h-75px w-75px rounded-circle cursor-pointer'
              src={imgPath}
              alt={providerName}
            />
          </th>
          <th>Payment Pending</th>
          <th>Paid</th>
          <th>In-Delivery</th>
          <th>Waiting Activation</th>
          {/* <th>Failed</th> */}
          <th>Activated</th>
        </tr>
        <tr className='text-center text-muted text-gray-900 fs-5 gs-0'>
          <td>{data.payment_pending}</td>
          <td>{data.paid}</td>
          <td>{data.indelivery}</td>
          <td>{data.waiting_activation}</td>
          {/* <td>{data.failed}</td> */}
          <td>{data.activated}</td>
        </tr>
      </table>
    </div>
  )
}

export default ProviderStats
