import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateTypeForm from '../components/CreateType';
import CreateNotificationContentForm from '../components/CreateNotificationContent';
import NotifcationType from '../../../../models/NotificationType';
import CreateNotificationEvent from '../components/CreateNotificationEvent';
import { KTSVG } from '../../../../_metronic/helpers';
import { Link, useNavigate } from 'react-router-dom';
import NotificationService from '../../../../services/notification.service';
import NotificationEvent from '../../../../models/NotificationEvent';

const NotificationsEventsScreen: React.FC = () => {
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [loading, setLoading] = React.useState(false)

    const [events, setEvents] = React.useState<NotificationEvent[]>([])
    const nav = useNavigate();
    const goToNotificationTypes = (id: number) => {
        nav(`/notifications/types/${id}`)
    }
    
    const fetchEvents = async () => {
        const response = await NotificationService.getEvents();
        setEvents(response.data)
    }
    React.useEffect(() => {
        fetchEvents()
    }, [])

    const handleSubmit = async (notificationEvent: NotificationEvent) => {
        setLoading(true);
        const bool = (await NotificationService.addEvents(notificationEvent)).data;
        if (bool) {
            fetchEvents()
            handleClose()
        }
        setLoading(false);
    }

    return (
        <div className={`card`}>
        {/* <Button variant="primary" onClick={handleShow}>
            Add Notification Type
        </Button> */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-4'>Notifications Events</span>
          </h3>
          {/* create btn */}

          <div className='d-flex' style={{position: 'absolute', right: '22px'}}>
            {/* <div className='d-flex align-items-center mr-2'>
          <KTSVG path='/media/icons/duotune/communication/com014.svg' className='svg-icon-1 svg-icon-primary' />
          <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
            {total} Total Users
          </span>
          </div> */}

            <div className='border border-gray-300 border-dashed rounded min-w-125px px-5  '>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen024.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{events.length}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Total events</div>
            </div>
            <div className='card-toolbar text-end'>
              {/* <Link
                to={'/cards/esim/create'}
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                
              </Link> */}
              <Button variant="primary" onClick={handleShow}>
              <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                </span>
                Create
                </Button>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-900 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead className='bg-gray-100 w-100'>
                  <tr className='border-0 d-flex justify-content-between align-items-center px-3'>
                    <th  style={{width: '170px'}} className='text-start'>
                      Title
                    </th>
                    <th className='text-start'>

                        Actions
                    </th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {events.map((event, index) => (
                <tr key={index} className='border-0 d-flex justify-content-between align-items-center px-3' >
                    <td>
                      <p>
                        {event.name}
                      </p>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-grow-1'>
                      <Button variant="secondary" onClick={()=>goToNotificationTypes(event.id)}>
                        
                            Notifications types
                            </Button>
                    </div>
                    </td>
                  </tr>
                )    
            )    
            }
                 
                  
                </tbody>
                {/* end::Table body */}
                </table>
            </div>
            {/* end::Table */}
            </div>
            {/* end::Table container */}
            </div>
            {/* end::Tap pane */}
            </div>


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {
                /////  selectedPromoCode ? 'Update Promo Code' : 'Add Promo Code'
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <CreateTypeForm init={null} id={0} handleSubmit={function (notifcationType: NotifcationType): void {
                        throw new Error('Function not implemented.');
                    } } /> */}
                    {
                        !loading ?
                  <CreateNotificationEvent init={null} id={0} handleSubmit={(n) => {
                    handleSubmit(n)
                  }
                  }/>: 
                  <div className="d-flex justify-content-center">
                    loading...
                    </div>
                    }
                
                
                {/* <PromoCodeForm init={selectedPromoCode} id={0} handleSubmit={handleSubmit} /> */}
            </Modal.Body>
        </Modal>
        </div>
    );
};

export default NotificationsEventsScreen;