import { REACT_APP_API_PROVIDERS } from "../config";
import axios from "./interceptors/axios.global";

const api = `${REACT_APP_API_PROVIDERS}/Country`

const getAllCountries = async () => {
    return axios.get(`${api}`);
}

const CountryService = {
    getAllCountries
}

export default CountryService;
