

const Loading = () => {
    return (
        <div className='col-12'>
            <div className='text-center'>
                <div className='spinner-border text-primary' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
            </div>
        </div>
    );
}

export default Loading;
