import {Fragment, useEffect, useRef, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {DateRangePicker} from 'react-nice-dates'
import {KTCard, KTCardBody, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import SimService from '../../../../services/sim.service'
import {enGB} from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import XLSX from 'xlsx'
import { Slide, toast } from 'react-toastify';
import "./table.css"; // Add your styling here

//modal
import {Modal, Button} from 'react-bootstrap'
import React from 'react'
const SimOrders = () => {
  const providerId = useParams().providerId
  const userId = useParams().id
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const imagePathSmall =
  searchParams.get('imagePathSmall') || toAbsoluteUrl('/media/logos/tygo_green.svg')
  const date = new Date()
  date.setDate(date.getDate() - 7)
  date.setHours(0, 0, 0, 0)
  const [startDate, setStartDate] = useState<any>(userId ? new Date(0) : date)
  const [endDate, setEndDate] = useState<any>(new Date(new Date().setHours(23, 59, 59, 999)))
  const [orderStatus, setOrderStatus] = useState<number>()
  const [error, setError] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagesCount, setPagesCount] = useState(0)
  const [currentPage_log, setCurrentPage_log] = useState(1)
  const [pagesCount_log, setPagesCount_log] = useState(0)
  const [orders, setOrders] = useState<any[]>([])
  const [searchedOrders, setSearchedOrders] = useState<any[]>([])
  const [totalOrders, setTotalOrders] = useState<number>()
  const phoneNumberInputRef = useRef<HTMLInputElement>(null)
  const [showModal, setShowModal] = useState("")
  const [newStatus, setNewStatus] = useState("-100")
  const [showLogs, setShowLogs] = useState<any | null>(null)
  const [orderSelected, setOrderSelected] = useState<any>()

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (rowIndex :any) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  const [data, setData] = useState<any[]>(
  [
    
  ]
);

  //================================================================================================


  const handleSubmitNewStatus = async () => {
    const response = await SimService.ChanegOrderState({ tygoOrderId: orderSelected?.tygoOrderId, newOrderStateCode: newStatus });
    if(response.status === 200)
    {
      notifySuccess();
      handleHideModal();
      fetchOrderData(
        startDate,
        endDate,
        currentPage,
        providerId as unknown as number,
        orderStatus,
        userId
      )
    } else {
      notifyError();
      handleHideModal();
    }
  }

  const notifySuccess = () => {
    toast.success('Operation successful!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide
    });
  };

  const notifyError = () => {
    toast.error('Operation failed!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide
    });
  }


  function decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}

  const SeeIfIsAdmin = () => {
    const jwtToken = localStorage.getItem('kt-auth-react-v') || '';

      // Step 2: Decode the Token
      const decodedToken = decodeJWT(jwtToken);

      // Step 3: Extract Roles from Decoded Token
        let roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
        if(roles_)
        {
          // if roles type string
          if(typeof roles_ === 'string')
          {
            roles_ = [roles_.toUpperCase(),];
          } else
          roles_ = roles_.map((role: string) => role.toUpperCase());
        }
        else 
        {
          roles_ = [];
          alert("you don't have any roles")
          //logout 
          localStorage.removeItem('kt-auth-react-v');
          window.location.href = '/auth/login';
        }
        return roles_.includes('ADMIN');
  }

  const handleShowModal = (e:string) => {
    setShowModal(e)
  }

  const handleHideModal = () => {
    setShowModal("")
  }

  const handleShowLogs = async (userId: string) => {
    fetchLogs(userId)
    setShowLogs(userId)
  }
  const fetchLogs = async (userId: string, currentPage_log?: number) => {
    const response = await SimService.GetOrderLogsByUserId({UserIds: [userId], pageNumber: currentPage_log || 1
      , PageSize: 1000000})
    setData(response.data.data);
    setPagesCount_log(response.data.totalPages)
    setCurrentPage_log(response.data.pageNumber)
  }


  const handleHideLogs = () => {
    setShowLogs(null)
  }


  useEffect(() => {

    fetchOrderData(
      startDate,
      endDate,
      currentPage,
      providerId as unknown as number,
      orderStatus,
      userId
    )
  }, [startDate, endDate, orderStatus])

  const fetchOrderData = async (
    start?: Date,
    end?: Date,
    pageNumber?: number,
    id?: number,
    status?: number,
    userId?: string
  ) => {
    try {
      // console.log('provider id:', id)
      const response = await SimService.GetOrderData({
        FromDate: start,
        ToDate: end,
        pageNumber,
        ProviderIds: id,
        PageSize: 20,
        OrderStatus: status,
        UserId: userId,
      })
      console.log('GetOrderData response:', response)
      setTotalOrders(response.data.totalRecords)
      setPagesCount(Math.ceil(response.data.totalRecords / 20))
      setCurrentPage(response.data.pageNumber)
      response.data.data.length > 0 ? setOrders(response.data.data[0].orders) : setOrders([])
      response.data.data.length > 0
        ? setSearchedOrders(response.data.data[0].orders)
        : setSearchedOrders([])
      setError(false)
    } catch (error) {
      setError(true)
      console.log(error)
    }
  }

  const fetchOrdersByPhoneNumber = async (phoneNumber: string) => {
    try {
      const numbers = [{phoneNumber, countryCode: '966'}]
      const data = {
        numbers,
      }
      const response = await SimService.getOrderDataByPhoneNumbrt(data)
      console.log('fetchOrdersByPhoneNumber response:', response)
      return response.data
    } catch (error) {
      setError(true)
      console.log(error)
    }
  }

  const handleSearch = async () => {
    console.log('phoneNumber:', phoneNumberInputRef.current?.value)
    const phoneNumber = phoneNumberInputRef.current?.value as string
    if (phoneNumber != '') {
      const ordersByPhoneNumber = await fetchOrdersByPhoneNumber(phoneNumber)
      console.log('ordersByPhoneNumber:', ordersByPhoneNumber)
      setSearchedOrders(ordersByPhoneNumber)
      setTotalOrders(ordersByPhoneNumber.length)
      setPagesCount(1)
    } else {
      await fetchOrderData(startDate, endDate, currentPage, providerId as unknown as number)
    }
  }

  const handleOrderStateColor = (state: string) => {
    console.log('state', state)
    if (state == 'Waiting Activation') return 'even-larger-badge badge badge-light-primary'
    else if (state == 'Activated') return 'even-larger-badge badge badge-light-success'
    else if (state == 'Paid') return 'even-larger-badge badge badge-light-warning'
    else if (state == 'Indelivery') return 'even-larger-badge badge badge-light-info'
    else return 'even-larger-badge badge badge-light'
  }

  const convertTimeTo12HourFormat = (timeString: any) => {
    // Splitting the time string into its components
    const timeComponents = timeString.split(':')

    // Extracting the hour, minute, and second
    const hour = parseInt(timeComponents[0])
    const minute = timeComponents[1]
    const second = timeComponents[2].split('.')[0] // Removing the milliseconds

    // Adjusting the hour to 12-hour format and determining AM or PM
    let adjustedHour = hour % 12
    const amPm = hour < 12 ? 'AM' : 'PM'

    // Handling special cases when the adjusted hour is 0
    if (adjustedHour === 0) {
      adjustedHour = 12 // 12 AM
    }

    // Creating the formatted time string
    const formattedTime = `${adjustedHour}:${minute}:${second} ${amPm}`

    return formattedTime
  }
  
  const handleExport = async () => {
    const response = await SimService.GetOrderData({
      FromDate: startDate,
      ToDate: endDate,
      pageNumber: 1,
      ProviderIds: providerId as unknown as number,
      PageSize: 100,
      OrderStatus: orderStatus,
      UserId: userId,
    })
    const totalPages = response.data.totalPages

    let financeDataAllPages:any[] = []

    for (let i = 1; i <= totalPages; i++) {
      const response = await SimService.GetOrderData({
        FromDate: startDate,
        ToDate: endDate,
        pageNumber: i,
        ProviderIds: providerId as unknown as number,
        PageSize: 100,
        OrderStatus: orderStatus,
        UserId: userId,
      })

      // const orders = await getOrdersFromPayments(response.data.data as any)

      // const financeData = createFinanceData(response?.data.data, orders)

      financeDataAllPages.push(...response.data.data)
    }

    let newData: any[] = []

    financeDataAllPages.forEach((data) => {
      data.orders.forEach((order: any) => {
        newData.push([
          order.id,
          order.orderId,
          order.orderState.nameEn,
          order.createdAt,
          order.fullName,
          order.idType,
          order.selectedSimType === 1 ? 'SIM' : order.selectedSimType === 2 ? 'eSIM' : '',
          order.phoneNumber,
          order.planName,
          order.orderTotalAmount,
        ])
      })
    }
      
      )


    const header = [
      // id	paymentId	paymentFor	name	email	amount	entityType	createdDate	requestDate	expectedDate	amountCredited	hyperPayExpense	paymentNetProfit	paymentStatus	orders	productId	productName	productCost	productSRP	productSold	productNETProfit
      'ID',
      'Order ID',
      'Order Status',
      'Order Date',
      'Customer Name',
      'Id Type',
      'SIM Type',
      'Mobile Number',
      'Product Name',
      'Total Amount'
    ]

    const wb = XLSX.utils.book_new()

    const ws = XLSX.utils.sheet_add_aoa(XLSX.utils.json_to_sheet(newData), [header])
    wb.SheetNames.push('Finance Sheet')
    wb.Sheets['Finance Sheet'] = ws

    XLSX.writeFile(wb, 'Finance Sheet.xlsx')
  }

  const handleExportLogs = async () => {
    const wb = XLSX.utils.book_new();

    // Prepare data by flattening the logs and adding userLogValues
    const flattenedData = data.flatMap(log => {
        const { userLogValues, ...rest } = log;
        
        // Create the main row
        const mainRow = {
            actionName: log.actionName,
            httpCode: log.httpCode,
            orderStatus: log.orderStatus,
            createdAt: log.createdAt,
            errorMessage: log.errorMessage,
            corrId: log.correlationId,
            userLogKey: '',  // Empty for the main row
            userLogValue: '' // Empty for the main row
        };
        
        // Create additional rows for each userLogValue
        const userLogRows = userLogValues.map((logValue: any) => ({
            actionName: '',  // Empty for nested rows
            httpCode: '',
            orderStatus: '',
            createdAt: '',
            errorMessage: '',
            corrId: '',
            userLogKey: logValue.key,
            userLogValue: logValue.value
        }));
        
        // Return the main row followed by the userLog rows
        return [mainRow, ...userLogRows];
    });

    // Add headers manually
    const ws = XLSX.utils.json_to_sheet(flattenedData, {
        header: ['actionName', 'httpCode', 'orderStatus', 'createdAt', 'errorMessage', 'corrId', 'userLogKey', 'userLogValue'],
    });

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Logs Sheet');

    // Write to file
    XLSX.writeFile(wb, 'Logs Sheet.xlsx');
};

  return (
    <div className='card'>
      {/* HEADER START */}
      <div className='card-header border-0 pt-5'>
        <div className='row align-items-center w-100'>
          <div className='col'>
            <img
              src={imagePathSmall as string}
              className='w-50px h-50px rounded-circle'
              alt='provider'
            />
          </div>

          <div className='col-4'>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(value) => {
                if (value)
                  setStartDate(
                    new Date(
                      value?.getFullYear() as number,
                      value?.getMonth() as number,
                      value?.getDate() as number,
                      0,
                      0,
                      0,
                      0
                    )
                  )
              }}
              onEndDateChange={(value) => {
                if (value)
                  setEndDate(
                    new Date(
                      value?.getFullYear() as number,
                      value?.getMonth() as number,
                      value?.getDate() as number,
                      23,
                      59,
                      59,
                      999
                    )
                  )
              }}
              maximumDate={new Date(new Date().setHours(23, 59, 59, 999))}
              minimumLength={1}
              format='dd MMM yyyy'
              locale={enGB}
            >
              {({startDateInputProps, endDateInputProps, focus}) => (
                <div className='date-range d-flex'>
                  <input
                    className={
                      'form-control form-control-lg form-control-solid input' +
                      (focus === 'startDate' ? ' -focused' : '')
                    }
                    {...startDateInputProps}
                    placeholder='Start date'
                    style={{borderRadius: '0.5rem 0 0 0.5rem'}}
                  />
                  <span className='date-range_arrow' />
                  <input
                    className={
                      'form-control form-control-lg form-control-solid input' +
                      (focus === 'endDate' ? ' -focused' : '')
                    }
                    {...endDateInputProps}
                    placeholder='End date'
                    style={{borderRadius: '0 0.5rem 0.5rem 0'}}
                  />
                </div>
              )}
            </DateRangePicker>
          </div>

          <div className='col-3 d-flex align-items-center position-relative'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control w-370px form-control-solid ps-14'
              placeholder='Search Order'
              ref={phoneNumberInputRef}
            />
            {/* button */}
            <button
              type='button'
              className='btn btn-sm btn-primary btn-active-light-primary position-absolute top-50 end-0 translate-middle-y me-1'
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          <div className='col-2'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold px-4'
              onChange={(e) => setOrderStatus(e.target.value as unknown as number)}
            >
              <option value=''>All</option>
              <option value='3'>Payment Pending</option>
              <option value='5'>Waiting Activation</option>
              <option value='7'>Indelivery</option>
              <option value='6'>Paid</option>
              {/* <option value='1'>Failed</option> */}
              <option value='8'>Activated</option>
            </select>
          </div>
          
          <div className='col d-flex'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px px-4  mb-5'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/communication/com014.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{totalOrders}</div>
              </div>
              <div className='fw-bold fs-6 text-gray-400'>Total Orders</div>
            </div>
          </div>
          <div className='col d-flex'>
            <button
              type='button'
              className='btn btn-sm btn-primary btn-active-light-primary'
              onClick={handleExport}
            >
              Export
            </button>
          </div>
        </div>
      </div>
      {/* HEADER END */}

      {/* BODY START */}
      {error ? (
        <div className='card'>
          <div className='card-body'>
            <h5 className='card-title'>Error</h5>
            <p className='card-text'>Something went wrong</p>
          </div>
        </div>
      ) : searchedOrders.length <= 0 ? (
        <div style={{height: '100%'}} className='card'>
          <div className='card-body'>
            <h5 className='card-title text-center card-text'>No matching orders were found</h5>
          </div>
        </div>
      ) : (
        <table
          id='kt_table_users'
          className='table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable'
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-6 text-uppercase gs-0'>
              <th>Order id</th>
              <th>Order Status</th>
              <th>Order Date</th>
              <th>Customer Name</th>
              <th>Id Type</th>
              <th>SIM Type</th>
              <th>Mobile Number</th>
              <th>Product Name</th>
              <th>Total Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {searchedOrders.map((order, index) => {
              return (
                <tr key={index} className='text-muted text-gray-600 fs-5 gs-0'>
                  <td className={'text-center min-w-100px mw-200px'}>{order.id}</td>
                  <td className='text-center'>
                    <span
                      className={`${handleOrderStateColor(
                        order.orderState && order.orderState.nameEn
                      )}`}
                    >
                      {order.orderState && order.orderState.nameEn}
                    </span>
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>
                    {order.createdAt.split('T')[0]}{' '}
                    {convertTimeTo12HourFormat(order.createdAt.split('T')[1])}
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>
                    <a href={`/accounts/${order.userId}`} className='btn btn-sm btn-light' style={{cursor: 'pointer'}}>
                    {order.fullName}
                    </a>
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.idType}</td>
                  <td className={`text-center min-w-100px mw-200px text-lg`}>
                    {order.selectedSimType === 1 ? (
                      <span className='even-larger-badge badge badge-light-warning text-lg'>
                        SIM
                      </span>
                    ) : order.selectedSimType === 2 ? (
                      <span className='even-larger-badge badge badge-light-info'>eSIM</span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.phoneNumber}</td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.planName}</td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.orderTotalAmount}</td>
                  <td className='text-center min-w-150px mw-200px d-flex justify-content-center'>
                    {SeeIfIsAdmin() &&
                      order.orderState && (order.orderState.nameEn === 'Paid' || order.orderState.nameEn === 'Waiting Activation') && (
                        <span className='btn btn-sm btn-light' onClick={
                          () => {
                            handleShowModal(order.orderState.nameEn)
                            setOrderSelected(order)
                          }
                        }>
                          <i className='bi bi-pencil'></i>
                        </span>)
                    }
                    <div className='m-1'></div>
                    <span className='btn btn-sm btn-light' onClick={() => handleShowLogs(order.userId)}>
                      <i className='bi bi-journal-richtext'></i>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {/* BODY END */}

      {/* FOOTER START */}
      {pagesCount > 1 && (
        <div className='col-12 mt-10 card p-3'>
          <div className='row'>
            <div className='col-12'>
              <div className='text-center'>
                <nav aria-label='Page navigation example'>
                  <ul className='pagination'>
                    <li className='page-item'>
                      <a className='page-link' href='#' aria-label='Previous'>
                        <span aria-hidden='true'>&laquo;</span>
                        <span className='sr-only'>Previous</span>
                      </a>
                    </li>
                    {Array(pagesCount)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <li
                            className={currentPage == index + 1 ? 'page-item active' : 'page-item'}
                            key={index}
                            onClick={() => {
                              setCurrentPage(index + 1)
                              fetchOrderData(
                                startDate,
                                endDate,
                                index + 1,
                                providerId as unknown as number,
                                orderStatus,
                                userId
                              )
                            }}
                          >
                            <a className='page-link'>{index + 1}</a>
                          </li>
                        )
                      })}
                    <li className='page-item'>
                      <a className='page-link' href='#' aria-label='Next'>
                        <span aria-hidden='true'>&raquo;</span>
                        <span className='sr-only'>Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* FOOTER END */}
      {/* MODAL Status change */}
      <Modal show={showModal != ""} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* PAID:
paid to waiting activation
paid to failed
 Waiting Activation:
waiting activation to Activated
waiting activation to Failed */}<div className='form-group'>
            <label htmlFor='exampleFormControlSelect1'>Select Order Status</label>
            <select className='form-control' id='exampleFormControlSelect1' onChange={(e) => setNewStatus(e.target.value)} value={newStatus}>
              {
                showModal === "Paid" ? (
                  <>
                    <option value='-100' >Failed</option>
                    <option value='600'>Waiting Activation</option>
                  </>
                ) : (
                  <>
                    <option value='700'>Activated</option>
                    <option value='-100' selected>Failed</option>
                  </>
                )
              }
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleHideModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmitNewStatus}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLogs != null} onHide={handleHideLogs} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>Logs</div>
              <div>
                <Button variant='primary' onClick={handleExportLogs}>
                  Export
                </Button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="custom-table">
          <thead>
            <tr>
            {/* "correlationId": "TYGO09242024185333",
                "transacationId": null,
                "actionName": "Checkout",
                "httpCode": "200",
                "orderStatus": "Payment Pending",
                "errorMessage": null,
                "createdAt": "2024-09-24T15:54:46.3275592", */}
              <th>actionName</th>
              <th>httpCode</th>
              <th>orderStatus</th>
              <th>createdAt</th>
              <th>errorMessage</th>
              <th>corrId</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => (
              <React.Fragment key={index}>
                <tr onClick={() => handleRowClick(index)} className={expandedRow === index ? 'expanded' : ''}>
                  <td>{d.actionName}</td>
                  <td>
                    <span
                      className={`status-tag ${
                        // employee.status === "Delivered" ? "delivered" : "warning"
                        d.httpCode === "200" ? "delivered" : "warning"
                      }`}
                    >
                      {d.httpCode}
                    </span>
                  </td>
                  <td>{d.orderStatus}</td>
                  <td>{d.createdAt}</td>
                  <td>{d.errorMessage}</td>
                  <td>{d.correlationId}</td>

                </tr>
                {expandedRow === index && (
                  <tr className="expanded-row">
                    <td colSpan={6}>
                      <div className="expanded-content">
                        {
                          d.userLogValues.length > 0 ?
                          d.userLogValues.map((employee: any, index: number) => (
                            <p key={index}><strong>{employee.key}:</strong> {employee.value}</p>
                          )):
                          <p className='text-center m-0 p-0'>
                            No log values</p>
                        }
                      
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className="pagination d-none">
  {pagesCount_log > 1 && (
    <ul>
      {/* First button */}
      {currentPage_log > 1 && (
        <li>
          <button
            onClick={() => {
              fetchLogs(showLogs, 1);
              setCurrentPage_log(1);
            }}
          >
            First
          </button>
        </li>
      )}

      {/* Previous button */}
      <li>
        <button
          onClick={() => {
            if (currentPage_log > 1) {
              fetchLogs(showLogs, currentPage_log - 1);
              setCurrentPage_log(currentPage_log - 1);
            }
          }}
        >
          Previous
        </button>
      </li>

      {
        // Pagination logic for showing 5 pages before and after the current page
        [...Array(pagesCount_log).keys()]
          .slice(
            Math.max(0, currentPage_log - 6), // Ensures at least 5 pages before current
            Math.min(pagesCount_log, currentPage_log + 5) // Ensures at most 5 pages after current
          )
          .map((index) => {
            return (
              <li
                className={currentPage_log === index + 1 ? 'active' : ''}
                key={index}
                onClick={() => {
                  fetchLogs(showLogs, index + 1);
                  setCurrentPage_log(index + 1);
                }}
              >
                <button>{index + 1}</button>
              </li>
            );
          })
      }

      {/* Next button */}
      <li>
        <button
          onClick={() => {
            if (currentPage_log < pagesCount_log) {
              fetchLogs(showLogs, currentPage_log + 1);
              setCurrentPage_log(currentPage_log + 1);
            }
          }}
        >
          Next
        </button>
      </li>

      {/* Last button */}
      {currentPage_log < pagesCount_log && (
        <li>
          <button
            onClick={() => {
              fetchLogs(showLogs, pagesCount_log);
              setCurrentPage_log(pagesCount_log);
            }}
          >
            Last
          </button>
        </li>
      )}
    </ul>
  )}
</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleHideLogs}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  )
}

export default SimOrders
