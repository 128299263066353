/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, sendOtp} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

const emailSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
})

const phoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Phone number is required'),
})

const loginSchema = Yup.object().shape({
  otp : Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(4, 'Maximum 4 symbols')
    .required('OTP is required')
    .matches(/^[0-9]+$/, 'OTP must be only digits'),
})


const initialValues = {
  email: '',
  // password: '',
  countryCode: '966',
  phoneNumber: '',
  otp: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [loginType, setLoginType] = useState('Email')
  const [phase, setPhase] = useState(1)
  const formik = useFormik({
    initialValues:initialValues,
    validationSchema: phase === 1 ? loginType === 'Email' ? emailSchema : phoneSchema : loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if(values.countryCode === '')
        values.countryCode = '966'

      setLoading(true)
      if(phase === 1) {
        try {
          console.log(values)
          const res = await sendOtp(values.email, values.phoneNumber, values.countryCode, loginType)
          if(res.request.status === 200) {
            setPhase(2)
            setLoading(false)
            setSubmitting(false)
            setStatus('OTP sent successfully')
          } else
          {
            setStatus('The login detail is incorrect')
            setLoading(false)
            setSubmitting(false) 
          }
        } catch (error) {
          setStatus('The login detail is incorrect')
          setLoading(false)
          setSubmitting(false)
          // setPhase(2)
          // setLoading(false)
          // setSubmitting(false)
          // setStatus('OTP sent successfully')

        }
        // saveAuth(auth)
          // const {data: user} = await getUserByToken(auth.token)
          // setCurrentUser(user)
        // } catch (error) {
        //   console.error(error)
        //   saveAuth(undefined)
        //   setStatus('The login detail is incorrect')
        //   setSubmitting(false)
        //   setLoading(false)
        // }
      } else {
        try {
          // export function login(email: string, otpCode: string, otpType: string, deviceType: string, phoneNumber: string, countryCode: string, deviceToken: string) {

          const {data: auth} = await login(
            values.email,
            values.otp,
            loginType,
            'Web',
            values.phoneNumber,
            values.countryCode,
            ''
            )

          
            // values.email, values.otp)
          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.token)
          setCurrentUser(user)
        }
        catch (error) {
          console.error(error)
          saveAuth(undefined)
          setStatus('The opt code is incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      }
    },

    
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      {
        phase === 1 ?
        <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Tygo</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
        </div>
        :
        <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Enter the OTP</h1>
        </div>
        
      }
      {/* begin::Heading */}

      {formik.status ? (
        phase === 1 ?
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
        :
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>

      ) : (
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
        <></>
      )}

      {/* begin::Form group */}
      {
        phase === 1 ?
        loginType == 'Email'?
         <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          // autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
        </div>
        :
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Phone number</label>
          <div className='row'>
          <div className='col-3'>
          <select
            placeholder='Country Code'
            // {...formik.getFieldProps('countryCode')}
            className={clsx(
              'form-control form-control-lg form-control-solid ',
              // {'is-invalid': formik.touched.email && formik.errors.countryCode},
              // {
              //   'is-valid': formik.touched.email && !formik.errors.countryCode,
              // }
            )}
            name='countryCode'
            autoComplete='off'
            onChange={(e) => {
              console.log(e.target.value)
              formik.setFieldValue('countryCode', e.target.value)
            }}

            value={formik.values.countryCode}
          >
            <option value='966'>+966</option>
          </select>
          </div>
          <div className='col-9'>
          <input
            placeholder='Phone number'
            {...formik.getFieldProps('phoneNumber')}
            className={clsx(
              'form-control form-control-lg form-control-solid col-9',
              {'is-invalid': formik.touched.email && formik.errors.phoneNumber},
              {
                'is-valid': formik.touched.email && !formik.errors.phoneNumber,
              }
            )}
            type='text'
            name='phoneNumber'
            autoComplete='off'
          />
          </div>
          </div>
          {formik.touched.email && formik.errors.phoneNumber && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.phoneNumber}</span>
            </div>
          )}

          {formik.touched.countryCode && formik.errors.countryCode && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.countryCode}</span>
              </div>
          )}

          
        </div>
        :
        <div className='fv-row mb-10'>
        {/* <label className='form-label fs-6 fw-bolder text-dark'>OTP</label> */}
        <input
          placeholder='OTP'
          {...formik.getFieldProps('otp')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.otp && formik.errors.otp},
            {
              'is-valid': formik.touched.otp && !formik.errors.otp,
            }
          )}
          type='password'
          name='otp'
          autoComplete='off'
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.otp}</span>
          </div>
        )}
        </div>
       
      }
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10 d-none'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>OTP Code</label>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Don't have one ?
            </Link>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Action */}
      {
        phase === 1 ?
        <div className='row'>
        <div className='col-7'>
        <button
          type='button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          onClick={() => {
            if(loginType == 'Email'){
              formik.setFieldValue('phoneNumber', '')
              formik.setFieldValue('countryCode', '')
              setLoginType('phoneNumber')
            }else{
              formik.setFieldValue('email', '')
              setLoginType('Email')
            }
          }}
        >
        
          {
            loginType == 'Email' ?
            <span className='indicator-label'><i className='bi bi-phone me-2 fs-1' /> login with phone</span>
            :
            <span className='indicator-label'><i className='bi bi-envelope me-2 fs-1' /> login with email</span>

          }          
        </button>
        </div>
        <div className='col-5'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Send Code</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        </div>
       
      </div>
      :
      <div className='row'>
      <div className='col-6'>
      <button
        type='button'
        className='btn btn-lg btn-light-primary w-100 mb-5'
        onClick={() => {
          setPhase(1)
          formik.setFieldValue('otp', '')
          formik.setFieldValue('phoneNumber', '')
          formik.setFieldValue('countryCode', '966')
          formik.setFieldValue('email', '')
          formik.setStatus('')
        }}
      >
        back
      </button>
      </div>
      <div className='col-6'>
      <button
        type='submit'
        id='kt_sign_in_submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className='indicator-label'>Verify</span>}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      </div>
      </div>
      }
      
      {/* end::Action */}
    </form>
  )
}
