import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import SimOrders from './components/SimOrders'
import Dashboard from './components/dashboard'

const OrdersManagementWrapper = () => {
  const ordersManagementBreadCrumbs: Array<PageLink> = [
    // {
    //   title: 'Dashboard',
    //   path: '/ordersManagement/dashboard',
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: '',
    //   path: '',
    //   isSeparator: true,
    //   isActive: false,
    // },
  ]

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='dashboard'
          element={
            <>
              <PageTitle breadcrumbs={ordersManagementBreadCrumbs}>Dashboard</PageTitle>
              <Dashboard />
            </>
          }
        />
        <Route
          element={
            <>
              <PageTitle breadcrumbs={ordersManagementBreadCrumbs}>List</PageTitle>
              <SimOrders />
            </>
          }
          path='providers/:providerId'
        />
        <Route
          element={
            <>
              <PageTitle breadcrumbs={ordersManagementBreadCrumbs}>List</PageTitle>
              <SimOrders />
            </>
          }
          path='providers'
        />
        <Route index element={<Navigate to='/ordersManagement/dashboard' />} />
      </Route>
    </Routes>
  )
}

export default OrdersManagementWrapper
