/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials'

type Props = {
  className: string
}

const Plans: React.FC<Props> = ({className}) => {
  const [plans, setPlans] = React.useState<any[]>([
    {
      id: 1,
      name: 'Mofawtar Basic',
      company: 'STC',
      status: 'info',
      users: 200,
    },
    {
      id: 2,
      name: 'Postpaid 50',
      company: 'Mobily',
      status: 'primary',
      users: 130,
    },
    {
      id: 3,
      name: 'Shabab 59',
      company: 'Zain',
      status: 'success',
      users: 121,
    },
    {
      id: 4,
      name: 'Saudi Passenger',
      company: 'Orange',
      status: 'warning',
      users: 100,
    },
    {
      id: 5,
      name: '70 SAR Plan',
      company: 'virgin',
      status: 'danger',
      users: 80,
    },

  ])
  
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Plans</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            >

            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {/* begin::Item */}
        {
          plans.map(plan => (
            <div className={`d-flex align-items-center bg-light-${plan.status} rounded p-5 mb-7`}>
          {/* begin::Icon */}
          <span className={`svg-icon svg-icon-${plan.status} me-5`}>
            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
          </span>
          {/* end::Icon */}
          {/* begin::Title */}
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
            {plan.name}
            </a>
            <span className='text-muted fw-semibold d-block'>+{plan.users} users</span>
          </div>
          {/* end::Title */}
          {/* begin::Lable */}
          <span className={`fw-bold py-1 text-${plan.status}`}>{plan.company}</span>
          {/* end::Lable */}
        </div>
          ))

        }
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Plans}
