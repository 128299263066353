import {useEffect, useState} from 'react'
import ProviderService from '../../../../services/provider.service'
import SimService from '../../../../services/sim.service'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ProviderStats from './ProviderStats'
import Loading from '../../../components/Loading'

type ProviderStats = {
  providerID: number
  providerName: string
  imagePath: string
  data: {
    payment_pending: number
    paid: number
    indelivery: number
    waiting_activation: number
    // failed: number
    activated: number
  }
}

type TYGOStats = {
  payment_pending: number
  paid: number
  indelivery: number
  waiting_activation: number
  // failed: number
  activated: number
  [key: string]: number
}

enum ProviderStatsEnum {
  payment_pending = 3,
  paid = 6,
  indelivery = 7,
  waiting_activation = 5,
  // failed = 1,
  activated = 8,
}

const Dashboard = () => {
  const navigate = useNavigate()

  const [hoveredProviderId, setHoveredProviderId] = useState<number | null>(null)
  const [providersStats, setProvidersStats] = useState<ProviderStats[]>([])
  const [tygoStats, setTygoStats] = useState<TYGOStats | null>(null)
  const [totalRecords, setTotalRecords] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const handleColors = (value: number) => {
    // console.log('value:', value)
    switch (value) {
      case 3:
        return 'primary'
      case 5:
        return 'secondary'
      case 6:
        return 'warning'
      case 7:
        return 'info'
      case 8:
        return 'success'
      default:
        return 'warning'
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      //  filter out AirAlo
      const providers_result = await ProviderService.getAll(0, 0)
      // setProviders(providers_result.data.returnData)

      // console.log('result:', providers_result.data)

      SimService.GetStatisticsNumber({}).then((result) => {
        console.log('result:', result)
        setTotalRecords(result.data.totalRecords)
      })

      let tygoStats: any = {}
      for (const key in ProviderStatsEnum) {
        if (isNaN(Number(key))) {
          const orderStatus = ProviderStatsEnum[key]
          const statistics_result = await SimService.GetStatisticsNumber({orderStatus})
          tygoStats[key] = statistics_result.data.totalRecords
        }
      }
      // console.log('tygoStats:', tygoStats)
      setTygoStats(tygoStats)

      // Object.keys(ProviderStatsEnum).forEach((key) => console.log(key))

      const providers = providers_result.data.returnData.map((provider: any) => {
        return {
          id: provider.id,
          name: provider.nameEn,
          imagePath: provider.imagePathSmall,
        }
      })

      providers.forEach(async (provider: any) => {
        let providerStat: any = {
          providerName: provider.name,
          imagePath: provider.imagePath,
          providerID: provider.id,
          data: {},
        }
        for (const key in ProviderStatsEnum) {
          if (isNaN(Number(key))) {
            const orderStatus = ProviderStatsEnum[key] as unknown as number
            const statistics_result = await SimService.GetStatisticsNumber({
              orderStatus,
              ProviderIds: provider.id,
            })
            providerStat.data[key] = statistics_result.data.totalRecords
          }
        }
        // console.log('providerStat:', providerStat)
        setProvidersStats((prev) => [...prev, providerStat])
      })

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setError(true)
    }
  }

  // Object.keys(ProviderStatsEnum).map((key, index) => {
  //   console.log('key:', key)
  //   console.log('index:', index)
  // })
  // console.log('providersStats:', providersStats)

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div style={{backgroundColor: 'white'}}>
          {error ? (
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'>Error</h5>
                <p className='card-text'>Something went wrong</p>
              </div>
            </div>
          ) : (
            <>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div
                  className='d-flex m-10 flex-column align-items-center text-center'
                  style={{
                    transform: hoveredProviderId === -1 ? 'scale(1.15)' : 'scale(1)',
                    transition: hoveredProviderId === -1 ? '250ms linear' : '',
                  }}
                >
                  <img
                    onClick={() => navigate(`/ordersManagement/providers`)}
                    onMouseEnter={() => setHoveredProviderId(-1)}
                    onMouseLeave={() => setHoveredProviderId(null)}
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/tygo_green.svg')}
                    className='h-40px w-125px h-125px rounded-circle cursor-pointer'
                  />
                  <div className='mt-5'>
                    <span className='h3'>{totalRecords}</span>
                  </div>
                </div>

                <div
                  className={`d-flex flex-wrap justify-content-center align-items-center`}
                  style={{marginBottom: 50}}
                >
                  {tygoStats &&
                    Object.keys(ProviderStatsEnum).map(
                      (key, index) =>
                        isNaN(Number(key)) && (
                          <div
                            className={`d-flex align-items-center justify-content-center m-8 p-5 rounded-5 bg-${handleColors(
                              ProviderStatsEnum[key as keyof typeof ProviderStatsEnum]
                            )}  badge badge-light-${handleColors(
                              ProviderStatsEnum[key as keyof typeof ProviderStatsEnum]
                            )} `}
                            style={{width: '200px'}}
                          >
                            <div className={`even-larger-badge `} style={{width: '100%'}}>
                              <div
                                style={{width: '100%'}}
                                key={index}
                                className='d-flex flex-column align-items-center text-center '
                              >
                                <div
                                  className={`m-2 px-4 badge badge-light-${handleColors(
                                    ProviderStatsEnum[key as keyof typeof ProviderStatsEnum]
                                  )}`}
                                  style={{width: '100%'}}
                                >
                                  <span className='m-auto h5 p-2'>
                                    {key.split('_')[0]} {key.split('_')[1]}
                                  </span>
                                </div>

                                <div
                                  className={`d-flex flex-column badge badge-light-${handleColors(
                                    ProviderStatsEnum[key as keyof typeof ProviderStatsEnum]
                                  )}`}
                                >
                                  <div className='mt-2'>
                                    <span className='h4 '>
                                      {tygoStats[key]} (
                                      {((tygoStats[key] / (totalRecords as number)) * 100).toFixed(
                                        1
                                      )}
                                      %)
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-center align-items-center'>
                {providersStats
                  .filter(
                    (providerStat) =>
                      providerStat.providerID !== 22 && providerStat.providerID !== 19
                  )
                  .map((providerStat) => (
                    <ProviderStats
                      key={providerStat.providerID}
                      providerID={providerStat.providerID}
                      providerName={providerStat.providerName}
                      imgPath={providerStat.imagePath}
                      data={providerStat.data}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Dashboard
